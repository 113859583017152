import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import firebase from "firebase";

const Login = () => import("../components/Login.vue");
const Verify = () => import("../views/Verify.vue");
const NotVerified = () => import("../views/NotVerified.vue");
const Offline = () => import("../views/Offline.vue");
const Reporte = () => import("../components/ReporteClaro.vue");
const Dashboard = () => import("../components/Dashboard.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
      outIfLoggedin: true,
    },
  },
  {
    path: "/verify",
    name: "verify",
    component: Verify,
    meta: {
      requiresAuth: false,
      outIfLoggedin: false,
    },
  },
  {
    path: "/notverified",
    name: "notverified",
    component: NotVerified,
    meta: {
      requiresAuth: true,
      outIfLoggedin: false,
    },
  },
  {
    path: "/offline",
    name: "offline",
    component: Offline,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/DashboardAcumulado",
    name: "dasboardAcumulado",
    component: Reporte,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("to:" + to.name);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const outIfLoggedin = to.matched.some((record) => record.meta.outIfLoggedin);
  const isAuthenticated = firebase.auth().currentUser;
  //console.log(store);
  // var isAuthenticated= store.getters.user.loggedIn
  // console.log(store.getters.user.loggedIn)

  // console.log(isAuthenticated)
  console.log(to.name + " requiere auth?: ", requiresAuth);
  console.log("Authenticated?: ", isAuthenticated);

  // forces the account info completition

  //   if (store != undefined && store != null && store.getters != null && store.getters.user.datosFb!= null  && store.getters.user.datosFb.status=="pending" && router.currentRoute!="/account") {
  //     console.log("dddddd")
  //     //next('/account')
  //   }
  //   else {
  //     console.log("cuenta activa. Continua")
  //   }
  // // forces..

  if (requiresAuth && (isAuthenticated == null || !isAuthenticated)) {
    next("/login");
  } else {
    next();
  }
  //next()
});

export default router;
