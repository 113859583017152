<template>
	<v-dialog v-model="dialogIframe" fullscreen hide-overlay transition="scale-transition" :scrollable="false" @keydown.esc="unmountIframe">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on" @click="loadIframe">
				<v-list-item-action>
					<v-icon>mdi-monitor-dashboard</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Control y Gestión</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</template>
		<v-card>
			<v-toolbar short dense>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn :rounded="true" dark @click="unmountIframe">
						Salir
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-container fluid>
				<iframe ref="chartiframe" :src="routeIframe" frameborder="0"></iframe>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<style>
	body {
		margin: 0;
	}

	iframe {
		height: calc(91vh - 5px);
		width: calc(100vw - 24px);
		box-sizing: border-box;
	}
</style>

<script>
	export default {
		computed: {},
		components: {},
		data: () => ({
			dialogIframe: false,
			routeIframe: "",
		}),
		methods: {
			loadIframe() {
				//console.log('MONTANDO IFRAME')
				const iframeRoute =
					"https://app.redash.io/rayo/public/dashboards/gN5jaox9Skk2EmXyVPtxGwIvBcd9qshMFmTlClvC";

				this.routeIframe = iframeRoute;
			},
			unmountIframe() {
				//console.log('DESMMONTANDO IFRAME')
				this.dialogIframe = false;
				this.routeIframe = "";
			},
		},
	};
</script>
