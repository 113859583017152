import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/index.js";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import vuetify from "./plugins/vuetify";

import * as firebase from "firebase";
import { firestorePlugin } from "vuefire";
import DatetimePicker from "vuetify-datetime-picker";
import VueExcelXlsx from "vue-excel-xlsx";

// new

// import Meta from 'vue-meta'
Vue.use(DatetimePicker);
Vue.use(VueExcelXlsx);
// Vue.use(Meta)
// import { Datetime } from 'vue-datetime'
// import 'vue-datetime/dist/vue-datetime.css'
// Vue.use(Datetime)
// Vue.component('datetime', Datetime)

Vue.use(firestorePlugin);

Vue.use(Vuetify);

Vue.config.productionTip = false;

const configOptions = {
  apiKey: "AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg",
  authDomain: "panel-53aab.firebaseapp.com",
  databaseURL: "https://panel-53aab.firebaseio.com",
  projectId: "panel-53aab",
  storageBucket: "panel-53aab.appspot.com",
  messagingSenderId: "288872450371",
  appId: "1:288872450371:web:895aa8d4babf9de2c847f6",
};

firebase.initializeApp(configOptions);

// const messaging = firebase.messaging()

var datosUserGo = {};
var perfilEmpresaGo = {};
var localArrayCecosOwner = [];
var userGo = {};

var promisesFB = [];
firebase.auth().onAuthStateChanged((user) => {
  promisesFB = [];
  var self = this;
  if (user != null && user != undefined) {
    userGo = user;
    // console.log(self)
    // if (user != null & user.emailVerified == false && router.currentRoute.name != 'verify' && router.currentRoute.name != 'notverified') {
    //	if (user.email != "dataclaro@rayoapp.com" && user.email != "juanandres@rayoapp.com") {

    if (user.email.substr(user.email.length - 11) != "rayoapp.com") {
      // console.log(router)
      router.push({
        name: "notverified",
      });
    } else {
      console.log("user correcto");
      store.dispatch("fetchUser", userGo);
      router.push({
        name: "dashboard",
      });
    }
  } else {
    console.log("no hay user");
    store.dispatch("fetchUser", null);
    router.push({
      name: "login",
    });
  }
});

Vue.config.productionTip = false;

new Vue({
  Vuetify,
  store,
  router,
  vuetify,
  render: (h) => h(App),
  firebase,
}).$mount("#app");
