<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :disable-resize-watcher="true"
      :dark="$vuetify.theme.dark"
      width="20%"
      v-model="drawer"
      app
      clipped
      bottom
    >
      <v-row align="start">
        <!-- <v-col align-self="start" class="pa-0" cols="12"></v-col> -->
        <v-col class="py-2" v-if="user != null && user != undefined">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle v-if="user != null && user.data != null">
                <v-icon class="mr-2" color="warning">mdi-email</v-icon>
                <strong>Email:</strong>
                {{ user.data.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-list v-if="user.data != null" dense>
        <v-list-item @click="drawer = false" link to="/DashboardAcumulado">
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Old Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link to="/Dashboard">
          <v-list-item-action>
            <v-icon>mdi-desktop-mac-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Beta Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Implementa Iframe Redash -->
        <RedashDashboard />
        <!-- End Implementación Iframe Redash -->
      </v-list>
      <p
        class="caption"
        v-if="
          user != undefined &&
          user.datosFb != undefined &&
          user.datosFb.perfil != 'propios' &&
          user.datosFb.perfil != 'cecos'
        "
      >
        Solo admins: {{ this.fcmToken }}
      </p>
      <div v-if="user.data != null" class="pa-2">
        <v-btn color="warning" @click="cerrarSesion()" block
          >Cerrar Sesión</v-btn
        >
      </div>
    </v-navigation-drawer>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon
        color="#1CACFC"
        v-if="user.loggedIn != false && user.data != null"
        @click.stop="drawer = !drawer"
      />
      <!-- <v-img max-width="25" src="https://rayoapp.com/assetsnew/images/copia-de-logo-3-128x127.png"></v-img> -->
      <v-img
        contain
        max-width="120"
        class="pa-2"
        src="https://www.rayoapp.com/img/2020/logo_celeste.png"
      ></v-img>

      <!-- <v-toolbar-title class="px-2">Pedidos Rayo:{{this.user.loggedIn }}</v-toolbar-title> -->
      <!-- <v-toolbar-title class="px-2">Rayo Delivery</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <span>
        <v-avatar
          @click="drawer = !drawer"
          size="34px"
          v-if="user.data != null && user.data.photo != null"
        >
          <v-img alt="Avatar" :src="user.data.photo"></v-img>
        </v-avatar>
        <v-avatar size="34px" v-else>
          <v-btn to="/login" fab :outlined="true">
            <v-icon color="primary">mdi-account-circle</v-icon>
          </v-btn>
        </v-avatar>
      </span>
      <span
        v-if="
          user != null &&
          user.data != null &&
          user.data.displayName != undefined
        "
        class="overline mb-4"
      >
        <p class="mt-8 ml-3">{{ user.data.displayName }}</p>
      </span>
      <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        <v-icon>mdi-brightness-4</v-icon>
      </v-btn>

      <!-- <v-switch prepend-icon="mdi-brightness-4" v-model="$vuetify.theme.dark"></v-switch> -->

      <!--  -->
    </v-app-bar>
    <v-content>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-row justify="center"></v-row>

        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer style="color: white" color="#1CACFC" app>
      <span>
        <a style="color: white" href="https://dataclaro.rayoapp.com"
          >dataclaro.rayoapp.com</a
        >
        2020 - v3.02
      </span>
    </v-footer>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import RedashDashboard from "./components/RedashDashboard.vue";

export default {
  props: {
    source: String,
  },
  components: { RedashDashboard },
  data: () => ({
    fcmToken: "",
    textoDialogoGenerico: "",
    tituloDialogoGenerico: "",
    destinoDialogoGenerico: "",

    dialogoGenerico: false,

    drawer: false,
    markers: [1, 2],
    // barColorLight: "#232222",
    // barColorDark: "#ffffff",

    fecha: "Bienvenid@ a Rayo",
    numerouser: 0,
  }),
  methods: {
    abreLink: function () {
      window.open(
        "https://datastudio.google.com/u/1/reporting/1gj38Zl_jT1Twgwdzfo9EBbnC0FHr-PJQ/page/qTLQB",
        "_blank"
      );
    },
    showPendingAlert() {
      this.alertaGenerica(
        "Tu Cuenta Aún No Está Activa.",
        "Acontinuación serás redireccionad@ a tu sección de perfil en donde podrás ingresar alguna información faltante para que puedas comenzar a usar Rayo.",
        "this.$router.push({name: 'account'})"
      );
    },
    alertaGenerica(titulo, texto, destino) {
      this.textoDialogoGenerico = texto;
      this.tituloDialogoGenerico = titulo;
      this.destinoDialogoGenerico = destino;
      //this.formNewEmailPassword = false;
      this.dialogoGenerico = true;
    },
    cerrarSesion() {
      var self = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("session closed");
          self.drawer = false;
          // self.$store.dispatch("fetchUser", null);
          // console.log(self.$router.currentRoute.name);
          if (self.$router.currentRoute.name == "login") {
            console.log("stay here");
          } else {
            self.$router.push({
              name: "login",
            });
          }
        });
    },
    goDark: () => {
      $vuetify.theme.dark = !$vuetify.theme.dark;
    },
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  mounted() {
    //console.log("mounted app.vue");
    const messaging = firebase.messaging();

    messaging
      .requestPermission()
      .then(() => {
        //  alert('test2')
        console.log("Notification permission granted.");

        // Get Token
        messaging.getToken().then((token) => {
          // alert(token)
          //      alert('test3 ')
          this.fcmToken = token;
          //console.log(token);
        });
      })
      .catch((err) => {
        // alert(
        // 	"No pudo conseguir acceso. Para recibir notifciaciones Push debes dar acceso a Rayo Delivery."
        // );
        console.log("Unable to get permission to notify.", err);
      });

    messaging.onMessage((payload) => {
      console.log("Message received. ", payload);
      // ...
    });
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
