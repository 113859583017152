var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"disable-resize-watcher":true,"dark":_vm.$vuetify.theme.dark,"width":"20%","app":"","clipped":"","bottom":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-row',{attrs:{"align":"start"}},[(_vm.user != null && _vm.user != undefined)?_c('v-col',{staticClass:"py-2"},[_c('v-list-item',[_c('v-list-item-content',[(_vm.user != null && _vm.user.data != null)?_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-email")]),_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.user.data.email)+" ")],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-divider'),(_vm.user.data != null)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":"/DashboardAcumulado"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-monitor-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Old Dashboard")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/Dashboard"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-desktop-mac-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Beta Dashboard")])],1)],1),_c('RedashDashboard')],1):_vm._e(),(
        _vm.user != undefined &&
        _vm.user.datosFb != undefined &&
        _vm.user.datosFb.perfil != 'propios' &&
        _vm.user.datosFb.perfil != 'cecos'
      )?_c('p',{staticClass:"caption"},[_vm._v(" Solo admins: "+_vm._s(this.fcmToken)+" ")]):_vm._e(),(_vm.user.data != null)?_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"color":"warning","block":""},on:{"click":function($event){return _vm.cerrarSesion()}}},[_vm._v("Cerrar Sesión")])],1):_vm._e()],1),_c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[(_vm.user.loggedIn != false && _vm.user.data != null)?_c('v-app-bar-nav-icon',{attrs:{"color":"#1CACFC"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-img',{staticClass:"pa-2",attrs:{"contain":"","max-width":"120","src":"https://www.rayoapp.com/img/2020/logo_celeste.png"}}),_c('v-spacer'),_c('span',[(_vm.user.data != null && _vm.user.data.photo != null)?_c('v-avatar',{attrs:{"size":"34px"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-img',{attrs:{"alt":"Avatar","src":_vm.user.data.photo}})],1):_c('v-avatar',{attrs:{"size":"34px"}},[_c('v-btn',{attrs:{"to":"/login","fab":"","outlined":true}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])],1)],1)],1),(
        _vm.user != null &&
        _vm.user.data != null &&
        _vm.user.data.displayName != undefined
      )?_c('span',{staticClass:"overline mb-4"},[_c('p',{staticClass:"mt-8 ml-3"},[_vm._v(_vm._s(_vm.user.data.displayName))])]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('v-icon',[_vm._v("mdi-brightness-4")])],1)],1),_c('v-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}}),_c('router-view')],1)],1),_c('v-footer',{staticStyle:{"color":"white"},attrs:{"color":"#1CACFC","app":""}},[_c('span',[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"https://dataclaro.rayoapp.com"}},[_vm._v("dataclaro.rayoapp.com")]),_vm._v(" 2020 - v3.02 ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }