import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      datosFb: null,
      paymentsModal: false,
      perfilEmpresa: null,
      cecosOwner: []
    }
  },
  getters: {
    user(state) {
      console.log(state)
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },

    SET_PAYMENT(state, value) {
      state.user.paymentsModal = value
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_DATOS_FB(state, data) {
      state.user.datosFb = data
    },
    SET_PERFIL_EMPRESA(state, data) {
      state.user.perfilEmpresa = data
    },
    SET_CECOS_OWNER(state, data) {
      state.user.cecosOwner = data
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      if (user) {
        console.log('true a user loggedin')
        commit('SET_LOGGED_IN', user !== null)
        // console.log(user)
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
          emailVerified: user.emailVerified,
          photo: user.photoURL,
          provider: user.providerData[0].providerId
        })
      } else {
        console.log('false a user loggedin')
        commit('SET_LOGGED_IN', false)
        commit('SET_USER', null)
      }
    },

    fetchUserDatos({ commit }, datos) {
      console.log('entrando actions 1')
      commit('SET_DATOS_FB', datos)
    // router.push({
    //   name: 'ingreso'
    // })
    },

    setPayment({ commit }, datos) {
      console.log('entrando actions 3')
      console.log(datos)
      commit('SET_PAYMENT', datos)
    // router.push({
    //   name: 'ingreso'
    // })
    },
    setPerfilEmpresa({ commit }, datos) {
      console.log('entrando actions commit perfil empresas EN VUEX')
      console.log(datos)
      commit('SET_PERFIL_EMPRESA', datos)
    // router.push({
    //   name: 'ingreso'
    // })
    },

    setCecosOwner({ commit }, datos) {
      console.log('entrando actions commit CECOS OWNER EN VUEX')
      console.log(datos)
      commit('SET_CECOS_OWNER', datos)
    // router.push({
    //   name: 'ingreso'
    // })
    }
  }
})
